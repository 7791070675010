import { styled } from "@styles/stitches";

export const StyledContainer = styled("div", {
  background: "$neutral-gray",
  borderRadius: "$default",
  padding: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  transition: "all 0.2s ease-in-out",
});
