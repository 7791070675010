import { createStitches } from "@stitches/react";

/* Theme */
import { Breakpoints, Colors, borderRadius } from "./src/theme/vars";

/* Types */
import type { BreakpointKeys, BreakpointType } from "./src/theme/vars";

export const { styled, css, globalCss, keyframes, getCssText, theme } =
  createStitches({
    theme: {
      shadows: {
        dropdown: "0 0 15px 1px rgba(0, 0, 0, 0.25)",
      },
      radii: { default: borderRadius },
      colors: {
        ...Colors,
        red: "rgb(var(--neutral-red))",
        green: "rgb(var(--neutral-green))",
        blue: "rgb(var(--neutral-blue))",
        yellow: "rgb(var(--neutral-yellow))",
        orange: "rgb(var(--neutral-orange))",
        rgbRed: "var(--neutral-red)",
        rgbGreen: "var(--neutral-green)",
      },
      fontSizes: {
        xs: "var(--text-xs)", // 12px
        sm: "var(--text-sm)", // 14px
        base: "var(--text-base)", // 16px
        lg: "var(--text-lg)", // 20px
        xl: "var(--text-xl)", // 24px
        "2xl": "var(--text-2xl)", // 32px
        "3xl": "var(--text-3xl)", // 48px
      },
      fontWeights: {
        400: 400,
        500: 500,
        700: 700,
      },
      lineHeights: {
        base: "var(--line-height-base)",
        xl: "var(--line-height-xl)",
        "2xl": "var(--line-height-2xl)",
        "3xl": "var(--line-height-3xl)",
      },
    },
    media: Object.keys(Breakpoints).reduce((acc, _bp) => {
      const bp = _bp as BreakpointKeys;
      acc[bp] = `(min-width: ${Breakpoints[bp]}px)`;
      return acc;
    }, {} as BreakpointType),

    utils: {
      textGradient: (value = "var(--neutral-gradient)") => ({
        background: value,
        "-webkit-background-clip": "text",
        "-moz-background-clip": "text",
        backgroundClip: "text",
        "-webkit-text-fill-color": "transparent",
      }),
    },
  });
