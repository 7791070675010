import { css, styled } from "@styles/stitches";

export const RichTextEditorStyles = css({
  // Editor Styles
  ".tiptap": {
    "& > *": {
      color: "$primary-text",
    },
    borderRadius: "0 0 12px 12px",
    backgroundColor: "$neutral-gray",
    color: "$primary-text",
    padding: "0.5rem 1rem",
    width: "100%",
    maxWidth: "100%",
    minHeight: "var(--min-height, 15rem)",
    "&:focus": {
      outline: "none",
    },

    "&>:is(h1, h2, h3, h4, h5, h6)": { fontWeight: 700 },
    "& h1": {
      fontSize: "clamp(2.125rem, 0.328vw + 2.113rem, 2.375rem)",
      lineHeight: "clamp(3.088rem, -0.131vw + 3.192rem, 3.188rem)",
    },
    "& h2": {
      fontSize: "clamp(2rem, 0.328vw + 1.988rem, 2.25rem)",
      lineHeight: "clamp(2.925rem, -0.098vw + 3.004rem, 3rem)",
    },
    "& h3": {
      fontSize: "clamp(1.75rem, 0.164vw + 1.744rem, 1.875rem)",
      lineHeight: "clamp(2.438rem, -0.246vw + 2.634rem, 2.625rem)",
    },
    "& h4": {
      fontSize: "clamp(1.5rem, 0.246vw + 1.491rem, 1.688rem)",
      lineHeight: "clamp(2.194rem, -0.074vw + 2.253rem, 2.25rem)",
    },
    "& h5": {
      fontSize: "clamp(1.25rem, 0.328vw + 1.238rem, 1.5rem)",
      lineHeight: "clamp(1.875rem, 0.098vw + 1.871rem, 1.95rem)",
    },
    "& h6": {
      fontSize: "clamp(1.125rem, 0.656vw + 1.1rem, 1.625rem)",
      lineHeight: "clamp(1.688rem, 0.557vw + 1.667rem, 2.113rem)",
    },
    "& p": {
      fontSize: "clamp(1.125rem, 0.164vw + 1.119rem, 1.25rem)",
      lineHeight: "clamp(1.625rem, -0.082vw + 1.691rem, 1.688rem)",
    },
    "& a": {
      color: "$blue",
      textDecoration: "underline",
      fontSize: "clamp(1.125rem, 0.164vw + 1.119rem, 1.25rem)",
      lineHeight: "clamp(1.625rem, -0.082vw + 1.691rem, 1.688rem)",
    },
    "& ul": {
      listStyleType: "disc",
      paddingInline: "1rem",
    },
    "& ul>li": {
      fontSize: "clamp(1.125rem, 0.164vw + 1.119rem, 1.25rem)",
      lineHeight: "clamp(1.625rem, -0.082vw + 1.691rem, 1.688rem)",
    },
    "& ol": {
      paddingInline: "1rem",
      listStyleType: "decimal",
    },
    "& ol>li": {
      fontSize: "clamp(1.125rem, 0.164vw + 1.119rem, 1.25rem)",
      lineHeight: "clamp(1.625rem, -0.082vw + 1.691rem, 1.688rem)",
    },
    "& code": {
      padding: "0.5rem",
      background: "$montonic",
      color: "$primary-text",
      borderRadius: "0.5rem",
      width: "100%",
      display: "block",
    },
    ".hljs-comment, .hljs-quote": { color: "$gray" },
    ".hljs-variable, .hljs-template-variable, .hljs-attribute, .hljs-tag, .hljs-name, .hljs-regexp, .hljs-link, .hljs-name, .hljs-selector-id, .hljs-selector-class":
      {
        color: "$red",
      },
    ".hljs-number, .hljs-meta, .hljs-built_in, .hljs-builtin-name, .hljs-literal, .hljs-type, .hljs-params":
      {
        color: "$orange",
      },
    ".hljs-string, .hljs-symbol, .hljs-bullet": { color: "$green" },
    ".hljs-title, .hljs-section": { color: "$yellow" },
    ".hljs-keyword, .hljs-selector-tag": { color: "$blue" },
    ".hljs-emphasis": { fontStyle: "italic" },
    ".hljs-strong": { fontWeight: 700 },
  },
});

export const ToolbarWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "0.75rem",
  padding: "0.5rem 1rem",
  background: "$secondary",
  borderRadius: "12px 12px 0 0",
});

export const ToolbarGroup = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "0.35rem",
});
