import * as React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const FacebookIcon: React.FC<Props> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M15.75 8.5a7.74 7.74 0 01-6.563 7.656V10.75H11l.344-2.25H9.188V7.062c0-.625.312-1.218 1.28-1.218h.97V3.937s-.876-.156-1.75-.156c-1.75 0-2.907 1.094-2.907 3.031V8.5H4.813v2.25H6.78v5.406C3.063 15.594.25 12.375.25 8.5A7.749 7.749 0 018 .75a7.749 7.749 0 017.75 7.75z"
        fill="#415E9B"
      />
    </svg>
  );
};

export const GoogleIcon: React.FC<Props> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
      <mask
        id="prefix__a"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={19}
        height={19}
      >
        <path
          d="M18.091 7.864H9.705v3.477h4.827c-.45 2.209-2.332 3.477-4.827 3.477A5.308 5.308 0 014.386 9.5a5.308 5.308 0 015.319-5.318c1.268 0 2.413.45 3.313 1.186l2.618-2.618C14.041 1.36 11.996.5 9.705.5c-4.991 0-9 4.01-9 9s4.009 9 9 9c4.5 0 8.59-3.273 8.59-9 0-.532-.081-1.105-.204-1.636z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path d="M-.114 14.818V4.182L6.841 9.5l-6.955 5.318z" fill="#FBBC05" />
      </g>
      <mask
        id="prefix__b"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={19}
        height={19}
      >
        <path
          d="M18.091 7.864H9.705v3.477h4.827c-.45 2.209-2.332 3.477-4.827 3.477A5.308 5.308 0 014.386 9.5a5.308 5.308 0 015.319-5.318c1.268 0 2.413.45 3.313 1.186l2.618-2.618C14.041 1.36 11.996.5 9.705.5c-4.991 0-9 4.01-9 9s4.009 9 9 9c4.5 0 8.59-3.273 8.59-9 0-.532-.081-1.105-.204-1.636z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          d="M-.114 4.182L6.841 9.5l2.863-2.495 9.819-1.596V-.318H-.114v4.5z"
          fill="#EA4335"
        />
      </g>
      <mask
        id="prefix__c"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={19}
        height={19}
      >
        <path
          d="M18.091 7.864H9.705v3.477h4.827c-.45 2.209-2.332 3.477-4.827 3.477A5.308 5.308 0 014.386 9.5a5.308 5.308 0 015.319-5.318c1.268 0 2.413.45 3.313 1.186l2.618-2.618C14.041 1.36 11.996.5 9.705.5c-4.991 0-9 4.01-9 9s4.009 9 9 9c4.5 0 8.59-3.273 8.59-9 0-.532-.081-1.105-.204-1.636z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__c)">
        <path
          d="M-.114 14.818L12.16 5.41l3.232.41 4.132-6.137v19.636H-.114v-4.5z"
          fill="#34A853"
        />
      </g>
      <mask
        id="prefix__d"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={19}
        height={19}
      >
        <path
          d="M18.091 7.864H9.705v3.477h4.827c-.45 2.209-2.332 3.477-4.827 3.477A5.308 5.308 0 014.386 9.5a5.308 5.308 0 015.319-5.318c1.268 0 2.413.45 3.313 1.186l2.618-2.618C14.041 1.36 11.996.5 9.705.5c-4.991 0-9 4.01-9 9s4.009 9 9 9c4.5 0 8.59-3.273 8.59-9 0-.532-.081-1.105-.204-1.636z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__d)">
        <path
          d="M19.523 19.318L6.84 9.5 5.205 8.273l14.318-4.091v15.136z"
          fill="#4285F4"
        />
      </g>
    </svg>
  );
};

export const GithubIcon: React.FC<Props> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.656 12.938c0-.063-.062-.126-.156-.126s-.156.063-.156.126c0 .062.062.124.156.093.094 0 .156-.031.156-.094zm-.968-.157c0 .063.062.156.156.156a.15.15 0 00.187-.062c0-.063-.031-.125-.125-.156-.093-.031-.187 0-.218.062zm1.406-.031c-.094 0-.157.063-.157.156 0 .063.094.094.188.063s.156-.063.125-.125c0-.063-.094-.125-.156-.094zm2.031-12C3.812.75.5 4.063.5 8.375c0 3.469 2.156 6.438 5.281 7.5.407.063.532-.188.532-.375v-1.938s-2.188.47-2.657-.937c0 0-.344-.906-.844-1.125 0 0-.718-.5.032-.5 0 0 .781.063 1.219.813.687 1.218 1.812.874 2.28.656.063-.5.25-.844.5-1.063-1.75-.187-3.53-.437-3.53-3.437 0-.875.25-1.282.75-1.844-.094-.219-.344-1.031.093-2.125.625-.188 2.157.844 2.157.844.625-.188 1.28-.25 1.937-.25.688 0 1.344.062 1.969.25 0 0 1.5-1.063 2.156-.844.438 1.094.156 1.906.094 2.125.5.563.812.969.812 1.844 0 3-1.844 3.25-3.594 3.437.282.25.532.719.532 1.469 0 1.031-.031 2.344-.031 2.594 0 .219.156.469.562.375 3.125-1.031 5.25-4 5.25-7.469C16 4.062 12.469.75 8.125.75zM3.531 11.531c-.062.031-.031.125 0 .188.063.031.125.062.188.031.031-.031.031-.125-.031-.188-.063-.03-.126-.062-.157-.03zm-.344-.25c-.03.063 0 .094.063.125s.125.031.156-.031c0-.031-.031-.063-.094-.094-.062-.031-.093-.031-.124 0zm1 1.125c-.03.031-.03.125.063.188.063.062.156.094.188.031.03-.031.03-.125-.032-.188-.062-.062-.156-.093-.218-.03zm-.343-.469c-.063.032-.063.126 0 .188.062.063.125.094.187.063.032-.032.032-.126 0-.188-.062-.063-.125-.094-.187-.063z"
        fill="#fff"
      />
    </svg>
  );
};

export const LinkedInIcon: React.FC<Props> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M3.625 14.5V5.156H.719V14.5h2.906zM2.156 3.906c.938 0 1.688-.781 1.688-1.719 0-.906-.75-1.656-1.688-1.656C1.25.531.5 1.281.5 2.187c0 .938.75 1.72 1.656 1.72zM14.47 14.5h.031V9.375c0-2.5-.563-4.438-3.5-4.438-1.406 0-2.344.782-2.75 1.5h-.031v-1.28H5.438V14.5h2.906V9.875c0-1.219.219-2.375 1.719-2.375s1.53 1.375 1.53 2.469V14.5h2.876z"
        fill="#016FAB"
      />
    </svg>
  );
};
