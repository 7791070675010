export type CheckboxVariant = keyof typeof VARIANTS;

export const VARIANTS = {
  filled: {
    border: "1px solid $primary",
  },
  borderless: {
    border: "none",
    background: "transparent",
  },
};

export const CHECKED = {
  true: {
    borderColor: "transparent",
    background: "$primary",
    color: "$primary",
  },
};

export const COMPOUND_VARIANTS = [
  {
    checked: "true",
    variant: "borderless",
    css: {
      background: "transparent",
      color: "$neutral-black",
    },
  },
];
