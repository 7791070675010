export type FontSizes = keyof typeof FONT_SIZES;
export type FontWeights = keyof typeof FONT_WEIGHTS;
export type FontColors = keyof typeof FONT_COLORS;
export type TextCases = keyof typeof FONT_TEXT_CASE;

export const FONT_SIZES = {
  xs: {
    fontSize: "$xs",
    lineHeight: "$base",
  },
  sm: {
    fontSize: "$sm",
    lineHeight: "$base",
  },
  base: {
    fontSize: "$base",
    lineHeight: "$base",
  },
  lg: {
    fontSize: "$lg",
    lineHeight: "$base",
  },
  xl: {
    fontSize: "$xl",
    lineHeight: "$xl",
  },
  "2xl": {
    fontSize: "$2xl",
    lineHeight: "$2xl",
  },
  "3xl": {
    fontSize: "$3xl",
    lineHeight: "$3xl",
  },
  inherit: {
    fontSize: "inherit",
    lineHeight: "inherit",
  },
};

export const FONT_WEIGHTS = {
  400: {
    fontWeight: "$400",
  },
  500: {
    fontWeight: "$500",
  },
  700: {
    fontWeight: "$700",
  },
};

export const FONT_COLORS = {
  primary: {
    color: "$primary-text",
  },
  gradient: {
    textGradient: "$neutral-gradient",
  },
  white: {
    color: "$neutral-white",
  },
  gray: {
    color: "$neutral-gray",
  },
  black: {
    color: "$neutral-black",
  },
  red: {
    color: "$red",
  },
  green: {
    color: "$green",
  },
  blue: {
    color: "$blue",
  },
  yellow: {
    color: "$yellow",
  },
  orange: {
    color: "$orange",
  },
  inherit: {
    color: "inherit",
  },
  purple: {
    color: "$neutral-purple",
  },
};

export const FONT_TEXT_CASE = {
  uppercase: {
    textTransform: "uppercase",
  },
  lowercase: {
    textTransform: "lowercase",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  none: {
    textTransform: "none",
  },
};
