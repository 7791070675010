import { styled } from "@styles/stitches";

export const StyledCard = styled("div", {
  borderRadius: "$default",
  padding: "0.75rem",
  background: "$neutral-gray",

  variants: {
    useGrid: {
      true: {
        display: "grid",
        gap: "1rem",
        gridTemplateRows: "var(--rows, 1fr 1fr)",
      },
    },
    layout: {
      horizontal: {
        gridTemplateRows: "var(--cols, 1fr 2fr)",

        "@md": {
          gridTemplateColumns: "var(--cols, 1fr 2fr)",
          gridTemplateRows: "1fr",
        },
      },
      vertical: {
        gridTemplateColumns: "1fr",
        gridTemplateRows: "var(--rows, 1fr 1fr)",
      },
    },
  },
});
