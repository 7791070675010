export type TextSizeType = "1" | "2" | "3" | "4" | "5" | "6" | "p";

export type TextSizeInterface = {
  label: string;
  value: TextSizeType;
};

export const TEXT_SIZES: TextSizeInterface[] = [
  {
    label: "Heading One",
    value: "1",
  },
  {
    label: "Heading Two",
    value: "2",
  },
  {
    label: "Heading Three",
    value: "3",
  },
  {
    label: "Heading Four",
    value: "4",
  },
  {
    label: "Heading Five",
    value: "5",
  },
  {
    label: "Heading Six",
    value: "6",
  },
  {
    label: "Paragraph",
    value: "p",
  },
];
