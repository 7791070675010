import React, { Suspense } from "react";
import ReactSkeleton from "react-loading-skeleton";

/* Type */
import { SkeletonProps } from "react-loading-skeleton";

type Props = {
  children?: React.ReactNode;
} & SkeletonProps;

export const SuspenseSkeleton: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  return <Suspense fallback={<ReactSkeleton {...rest} />}>{children}</Suspense>;
};
