import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

/* Components */
import { CheckboxInput, CheckboxContainer } from "./styled";

/* Types */
import type { CheckboxVariant } from "./types";
import { Label } from "@components/shared";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type Props = {
  children?: React.ReactNode;
  wrapperClassName?: string;
  variant?: CheckboxVariant;
  label?: string;
  onChange?: (checked: boolean) => void;
} & Omit<InputProps, "onChange">;

export const Checkbox: React.FC<Props> = (props) => {
  const { children, containerProps, onChange, wrapperClassName, rest } =
    DefaultProps(props);

  const [checked, setChecked] = React.useState(rest.checked);

  return (
    <fieldset
      role="button"
      tabIndex={0}
      onClick={() => {
        setChecked(!checked);
        onChange && onChange(!checked);
      }}
      className={`flex items-center gap-12 px-4 py-2 ${wrapperClassName}`}
    >
      <Label label={rest.label} htmlFor={rest.name} />
      <CheckboxContainer
        onClick={() => {
          setChecked(!checked);
          onChange && onChange(!checked);
        }}
        {...containerProps}
        checked={checked && "true"}
      >
        {checked && <CheckIcon fill="white" width={16} height={16} />}
      </CheckboxContainer>
      <CheckboxInput type="checkbox" value={String(checked)} {...rest} />
      {children}
    </fieldset>
  );
};

const DefaultProps = (props: Props) => {
  const {
    children,
    wrapperClassName,
    variant = "filled",
    className,
    style,
    onChange,
    ...rest
  } = props;

  const defaultProps = {
    children,
    wrapperClassName,
    rest,
    onChange,
    containerProps: {
      variant,
      checked: rest.checked,
      className,
      style,
    },
  };

  return defaultProps;
};
