"use client";

import * as Y from "yjs";
import { useEditor, EditorContent, findChildren } from "@tiptap/react";
import Collaboration from "@tiptap/extension-collaboration";
import React, { useCallback, useEffect, useMemo, useState } from "react";

/* Components */
import { Mark } from "./components/mark";
import { generateExtensions } from "./extension";
import { Select } from "@components/Select/Single";

/* Consts */
import { TEXT_SIZES, TextSizeInterface } from "./consts";

/* Styles */
import {
  RichTextEditorStyles,
  ToolbarGroup,
  ToolbarWrapper,
} from "./components/styled";

/* Types */
import type { DropdownOption } from "@components/types";
import type { EditorContentProps, EditorEvents } from "@tiptap/react";
import type {
  ExtensionType,
  FileHandlerOnDrop,
  FileHandlerOnPaste,
} from "./types";

export type EditorProps = {
  extensions?: ExtensionType[];
  isEditable?: boolean;
  onPaste?: FileHandlerOnPaste;
  onDrop?: FileHandlerOnDrop;
  onChange?: (htmlContent: string) => void;
  defaultContent?: string;
} & Omit<
  EditorContentProps,
  "ref" | "editor" | "className" | "onChange" | "onPaste" | "onDrop"
>;

type SetSizeType = React.Dispatch<React.SetStateAction<TextSizeInterface>>;

const onTransaction = (
  { editor }: EditorEvents["transaction"],
  setSize: SetSizeType,
) => {
  const isHeading = editor.isActive("heading");
  const isParagraph = editor.isActive("paragraph");
  const headingLevel = editor.getAttributes("heading")?.level;

  if (isParagraph) {
    setSize(TEXT_SIZES.at(-1)!);
    return;
  }

  if (isHeading && headingLevel) {
    setSize(TEXT_SIZES.find((item) => item.value == headingLevel)!);
    return;
  }
};

export function CustomRichTextEditor(props: EditorProps): JSX.Element {
  const {
    extensions,
    isEditable,
    defaultContent,
    onPaste,
    onDrop,
    onChange,
    ...editorProps
  } = DefaultProps(props);

  const doc = new Y.Doc();

  /* Memos */
  const textSizes = useMemo(() => TEXT_SIZES, []);
  const memoizedExtensions = useMemo(() => {
    const exts = generateExtensions(extensions!);

    if (onPaste || onDrop)
      exts.fileHandler = exts.fileHandler.configure({
        ...(onPaste && { onPaste }),
        ...(onDrop && { onDrop }),
      });

    const finalExts = Object.values(exts);
    finalExts.push(Collaboration.configure({ document: doc }) as any);

    return finalExts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extensions, onDrop, onPaste]);

  /* States */
  const [editorInit, setEditorInit] = useState(false);
  const [size, setSize] = useState<TextSizeInterface>(TEXT_SIZES.at(-1)!);

  /* Hooks */
  const editor = useEditor({
    extensions: memoizedExtensions,
    editable: isEditable,
    onTransaction(p) {
      onTransaction(p, setSize);
    },
    onUpdate({ editor }) {
      props.onChange?.(editor.getHTML());
    },
  });

  /* Handlers */
  /* Set text size for editor */
  const setTextSize = useCallback(
    (opt: DropdownOption) => {
      setSize(opt);
      if (!editor) return;

      if (opt.value === "p") {
        editor.chain().focus().setParagraph().run();
        return;
      }

      try {
        const level = parseInt(opt.value) as 1 | 2 | 3 | 4 | 5 | 6;
        editor.chain().focus().toggleHeading({ level }).run();
      } catch (error) {
        console.error(`Couldn't set text size: ${error}`);
      }
    },
    [editor],
  );

  /* Listeners */
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case "Tab":
          e.preventDefault();
          editor?.chain().focus().insertContent("   ").run();

          break;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /* Effects */
  useEffect(() => {
    if (editor && defaultContent && !editorInit) {
      setEditorInit(true);
      editor.commands.setContent(defaultContent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return (
    <div className="grid grid-rows-[1fr_auto]">
      <ToolbarWrapper>
        <ToolbarGroup>
          <Select
            name="text-size"
            value={size}
            onChange={setTextSize}
            options={textSizes}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <Mark.Bold editor={editor} />
          <Mark.Italic editor={editor} />
          <Mark.StrikeThrough editor={editor} />
          <Mark.Underline editor={editor} />
          <Mark.Superscript editor={editor} />
          <Mark.Subscript editor={editor} />
          <Mark.Link editor={editor} />
        </ToolbarGroup>
        <ToolbarGroup>
          <Mark.BulletList editor={editor} />
          <Mark.OrderedList editor={editor} />
          <Mark.Code editor={editor} />
        </ToolbarGroup>
      </ToolbarWrapper>
      <EditorContent
        {...editorProps}
        onKeyDown={onKeyDown}
        className={RichTextEditorStyles()}
        editor={editor}
      />
      ;
    </div>
  );
}

const DefaultProps = (props: EditorProps) => {
  const finalProps = {
    ...props,
    defaultContent: props.defaultContent ?? "",
    isEditable: props.isEditable ?? true,
    extensions: props.extensions ?? [],
  };

  return finalProps as EditorProps;
};
