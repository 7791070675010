import React, { useMemo } from "react";

/* Components */
import { StyledCard } from "./styled";

type StyledProps = typeof StyledCard.defaultProps;

type Props = {
  children?: React.ReactNode;
  cols?: string;
  rows?: string;
  style?: React.CSSProperties & Record<string, string | number>;
} & StyledProps;

export const Card: React.FC<Props> = (props) => {
  const { cols, rows, style, ...rest } = DefaultProps(props);

  const styles = useMemo(
    () => ({
      "--cols": cols,
      "--rows": rows,
      ...style,
    }),
    [cols, rows, style]
  );

  return (
    <StyledCard {...rest} style={styles}>
      {props.children}
    </StyledCard>
  );
};

const DefaultProps = (props: Props) => {
  const finalProps: Props = {
    ...props,
    useGrid: props.useGrid ?? true,
  };

  return finalProps;
};
