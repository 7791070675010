import React from "react";
import type { WithToolbarMarkItemProps, ToolbarMarkItem } from "../types";

export const withToolbarMarkDefaults = (
  Component: React.FC<WithToolbarMarkItemProps>
) => {
  const buttonProps = {
    variant: "outline",
    size: "xs",
    style: { "--z-index": 1 } as React.CSSProperties,
    iconButton: true,
  } as const;

  const iconProps = {
    size: 16,
    color: "currentColor",
    className: "text-primary-text",
  };

  return function ToolbarItem(props: ToolbarMarkItem) {
    return (
      <Component {...props} buttonProps={buttonProps} iconProps={iconProps} />
    );
  };
};
