"use client";

import React, { useMemo } from "react";

/* Styled */
import { EffectCircle } from "./styled";

type Props = {
  children?: React.ReactNode;
  className?: string;
  color?: "pink" | "purple";
  size?: string | number;
  intensity?: number;
} & Pick<React.CSSProperties, "top" | "left" | "right" | "bottom">;

export const CircleLight: React.FC<Props> = (props) => {
  let {
    className = "",
    intensity = 50,
    color = "pink",
    size = "10rem",
    ...rest
  } = props;

  intensity = intensity > 100 ? 100 : intensity;

  const { bgIntensity, shadowIntensity } = useMemo(
    () => ({
      bgIntensity:
        (intensity / 100) * 0.18 - Math.max(0.008, intensity / 10000),
      shadowIntensity: (intensity / 100) * 0.2,
    }),
    [intensity],
  );

  return (
    <EffectCircle
      className={className}
      color={color}
      style={
        {
          "--size": size,
          "--shadow-intensity": shadowIntensity,
          "--bg-intensity": bgIntensity,
          ...rest,
        } as any
      }
    />
  );
};
