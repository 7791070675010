import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getFilteredRowModel,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

/* Consts */
import { ROW_OPTIONS } from "./consts";

/* Components */
import { TableHead } from "./components/head";
import { TableBody } from "./components/body";
import { Heading } from "@components/Typography";
import { Pagination } from "./components/pagination";
import { GlobalFilter } from "./components/GlobalFilter";
import { ColumnVisibility } from "./components/columnVisibility";

import {
  StyledTable,
  TableWrapper,
  TableContainer,
  TableComponentContainer,
} from "./styled";

/* Types */
import type { PaginationState } from "@tanstack/react-table";
import type { DefaultFilter, PaginationInterface } from "./types";
import type { ColumnDef, Table as TableInstance } from "@tanstack/react-table";

type Props = {
  children?:
    | React.ReactNode
    | ((table: TableInstance<Record<string, unknown>>) => React.ReactNode);
  cols: ColumnDef<any>[];
  rows: any[];
  title?: string;
  onRowClick?: (original: Object) => void;
  hasStickyHeader?: boolean;
  searchPlaceholder?: string;
  defaultFilters?: DefaultFilter[];
  searchClassName?: string;
  errorHeading?: string;
  errorText?: string;
  childrenBeforeSearch?: boolean;
} & (
  | {
      manualPagination?: true;
      pagination: PaginationInterface;
    }
  | {
      manualPagination?: false;
      pagination: null | never;
    }
);

export const Table: React.FC<Props> = (props) => {
  const {
    cols,
    rows: Data,
    hasStickyHeader = true,
    children,
    defaultFilters,
    childrenBeforeSearch,
    errorHeading,
    errorText,
    pagination,
    manualPagination,
  } = DefaultProps(props);

  /* Memos */
  const columns = useMemo(() => cols, [cols]);
  const data = useMemo(() => Data, [Data]);
  const defaultValue = useMemo(() => ROW_OPTIONS[1], []);

  /* States */
  const [columnVisibility, setColumnVisibility] = useState({});
  const [paginationState, setPagination] = useState<PaginationState>({
    pageIndex: pagination?.currentPage || 0,
    pageSize: pagination?.size || defaultValue.value,
  });

  /* Hooks */
  const table = useReactTable({
    columns: columns,
    data,
    state: { columnVisibility },
    pageCount: pagination?.totalPages,
    onColumnVisibilityChange: setColumnVisibility,
    initialState: {
      pagination: paginationState,
      columnFilters: defaultFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination,
    ...(manualPagination
      ? {
          onPaginationChange: setPagination,
        }
      : { getPaginationRowModel: getPaginationRowModel() }),
  });

  /* Effects */
  useEffect(() => {
    if (!pagination) return;

    pagination.onPageChange(paginationState.pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState.pageIndex]);

  /* Memo */
  const RenderChildren = useMemo(
    () => (children instanceof Function ? children(table) : children),
    [children, table],
  );

  return (
    <TableComponentContainer>
      <div className="grid grid-cols-2">
        <Heading size="2xl" className="mt-1" weight={700}>
          {props.title}
        </Heading>

        <div className="flex w-full gap-4 justify-end">
          {childrenBeforeSearch ? RenderChildren : null}

          <GlobalFilter
            table={table}
            searchPlaceholder={props.searchPlaceholder}
            searchClassName={props.searchClassName}
          />
          {!childrenBeforeSearch ? RenderChildren : null}
          {/* <ColumnVisibility table={table} /> */}
        </div>
      </div>
      <TableWrapper>
        <TableContainer>
          <StyledTable>
            <TableHead hasStickyHeader={hasStickyHeader} table={table} />
            <TableBody
              errorHeading={errorHeading}
              errorText={errorText}
              table={table}
              onRowClick={props.onRowClick}
            />
          </StyledTable>
        </TableContainer>
      </TableWrapper>
      <Pagination
        pagination={pagination}
        table={table}
        defaultValue={defaultValue}
      />
    </TableComponentContainer>
  );
};

const DefaultProps = (props: Props) => {
  const defaultProps = {
    ...props,
    manualPagination: props.manualPagination || false,
    pagination: props.pagination || null,
    errorHeading: props.errorHeading || "Oops, Nothing was Found!",
    defaultFilters: props.defaultFilters || [],
    title: props.title || "",
    childrenBeforeSearch: props.childrenBeforeSearch || false,
    hasStickyHeader: props.hasStickyHeader || true,
    searchClassName: props.searchClassName || "",
  };

  if (!props.manualPagination) defaultProps.pagination = null;

  return defaultProps as typeof defaultProps;
};
