import { styled } from "@styles/stitches";

/* Consts */
import { VARIANTS, CHECKED, COMPOUND_VARIANTS } from "./consts";

export const CheckboxInput = styled("input", {
  appearance: "none",
});

export const CheckboxContainer = styled("div", {
  display: "flex",
  borderRadius: "4px",
  background: "$white",
  height: "1rem",
  aspectRatio: "1/1",
  transition: "all 200ms ease-in-out",

  variants: {
    checked: CHECKED,
    variant: VARIANTS,
  },
  compoundVariants: COMPOUND_VARIANTS,
});
