import React from "react";
// import { Player } from "@lottiefiles/react-lottie-player";

/* Components */
import { Text, Heading } from "@components";
import { StyledTableDetail, StyledTableRow } from "../styled";

/* Types */
import type { TableComponent } from "../types";
import { flexRender } from "@tanstack/react-table";

type Props = {
  onRowClick?: (original: Object) => void;
  errorHeading: string;
  errorText?: string;
} & TableComponent;

export const TableBody: React.FC<Props> = (props) => {
  const { table, onRowClick } = props;

  const { getRowModel } = table;

  return (
    <tbody>
      {getRowModel().rows.map((row, i) => (
        <StyledTableRow
          key={row.id}
          className="border-b cursor-pointer"
          onClick={() => onRowClick && onRowClick(row.original)}
        >
          {row.getVisibleCells().map((cell, i) => (
            <StyledTableDetail key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </StyledTableDetail>
          ))}
        </StyledTableRow>
      ))}
      {getRowModel().rows.length === 0 && (
        <tr className="absolute top-1/2 -translate-y-1/2 w-full">
          {/* <Player autoplay className="w-64 h-64" src={animationData} loop /> */}
          <td className="flex flex-col items-center gap-2">
            <Heading size="xl" weight={500}>
              {props.errorHeading}
            </Heading>
            {props.errorText && <Text>{props.errorText}</Text>}
          </td>
        </tr>
      )}
    </tbody>
  );
};
