type Props = React.SVGProps<SVGSVGElement>;

export function SunIcon(props: Props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        d="M7 3.813a.432.432 0 01-.438-.438V1.187A.45.45 0 017 .75a.47.47 0 01.438.438v2.187A.45.45 0 017 3.813zm.438 10.5v-2.188A.47.47 0 007 11.687a.45.45 0 00-.438.438v2.188c0 .246.192.437.438.437a.45.45 0 00.438-.438zM14 7.75a.47.47 0 00-.438-.438h-2.187a.45.45 0 00-.438.438c0 .246.192.438.438.438h2.188A.45.45 0 0014 7.75zm-10.938 0a.47.47 0 00-.437-.438H.437A.45.45 0 000 7.75c0 .246.191.438.438.438h2.187a.45.45 0 00.438-.438zm7.329-2.762l1.558-1.558c.164-.164.164-.438 0-.63-.191-.163-.465-.163-.629 0L9.762 4.36c-.164.163-.164.437 0 .628.11.082.218.11.328.11.11 0 .219-.028.3-.11zM2.652 12.7l1.559-1.531c.164-.164.164-.438 0-.629-.191-.164-.465-.164-.629 0l-1.559 1.559a.43.43 0 000 .601c.11.11.22.137.329.137.109 0 .218-.027.3-.137zm9.297 0a.429.429 0 000-.601l-1.558-1.559c-.164-.164-.438-.164-.63 0-.163.191-.163.465 0 .629l1.56 1.559c.081.082.19.109.3.109.11 0 .219-.027.328-.137zM4.211 4.99c.164-.192.164-.466 0-.63L2.652 2.801c-.164-.164-.437-.164-.629 0-.164.191-.164.465 0 .629l1.559 1.558c.082.082.191.11.3.11.11 0 .247-.028.329-.11zm5.851 2.761c0-1.668-1.394-3.063-3.062-3.063A3.075 3.075 0 003.937 7.75 3.057 3.057 0 007 10.813a3.075 3.075 0 003.063-3.063zm-.874 0c0 1.23-.985 2.188-2.188 2.188A2.16 2.16 0 014.812 7.75c0-1.203.958-2.188 2.188-2.188 1.203 0 2.188.985 2.188 2.188z"
        fill={props.fill || "#2E1752"}
      />
    </svg>
  );
}

export function MoonIcon(props: Props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 13" fill="none" {...props}>
      <path
        d="M11.703 9.813a.72.72 0 00-.738-.329 3.984 3.984 0 01-3.254-.82c-.93-.793-1.477-1.914-1.477-3.117 0-1.45.793-2.79 2.024-3.5.246-.137.383-.438.355-.738A.73.73 0 008.04.734C7.74.68 7.246.625 6.918.625 3.582.625.875 3.387.875 6.75c0 3.39 2.707 6.125 6.043 6.125 1.86 0 3.555-.82 4.73-2.27.192-.218.192-.546.055-.793zM6.918 12C4.074 12 1.75 9.648 1.75 6.75c0-2.871 2.324-5.25 5.168-5.25.137 0 .328.027.492.055a4.921 4.921 0 00-2.05 3.992c0 1.476.656 2.844 1.777 3.773a4.912 4.912 0 003.527 1.094A5.211 5.211 0 016.918 12z"
        fill={props.fill || "#fff"}
      />
    </svg>
  );
}

export const UserIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      fill="none"
      {...props}
    >
      <path
        fill={props.fill || "#fff"}
        d="M24.458 8.136c-4.258 0-7.72 3.323-7.72 7.41 0 4.01 3.267 7.255 7.525 7.395.13-.016.26-.016.357 0h.114c4.16-.14 7.427-3.386 7.443-7.395 0-4.087-3.462-7.41-7.72-7.41ZM32.713 27.09c-4.534-2.901-11.928-2.901-16.495 0-2.063 1.327-3.201 3.12-3.201 5.04 0 1.919 1.138 3.697 3.185 5.008 2.275 1.466 5.265 2.2 8.256 2.2 2.99 0 5.98-.734 8.255-2.2 2.048-1.326 3.185-3.105 3.185-5.04-.016-1.918-1.137-3.697-3.185-5.007Z"
      />
    </svg>
  );
};
