import React, { useCallback, useMemo, useState } from "react";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

/* Components */
import { SortableOverlay } from "./components/Overlay";

/* Types */
import type { SortingStrategy } from "@dnd-kit/sortable";
import type { DragEndEvent, Modifiers, UniqueIdentifier } from "@dnd-kit/core";

interface BaseItem {
  id: UniqueIdentifier;
}

type Props<T extends BaseItem> = {
  onChange(items: T[], dragIdx: number, dropIdx: number): void;
  renderItem(item: T, index: number): React.ReactNode;
  items: T[];
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  wrapperClassName?: string;
  keyPrefix: string;
};

export function DnDSortable<T extends BaseItem>(props: Props<T>) {
  const {
    keyPrefix,
    items,
    onChange,
    renderItem,
    strategy,
    wrapperClassName,
    modifiers,
  } = DefaultProps(props);

  /* States */
  const [dragIdx, setDragIdx] = useState<UniqueIdentifier | null>(null);

  /* Handlers */
  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      setDragIdx(null);
      if (!over) return;

      if (active.id !== over.id) {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        let modules = [...items];
        onChange(arrayMove(modules, oldIndex, newIndex), oldIndex, newIndex);
      }
    },
    [items, onChange]
  );

  const { activeItem, activeIdx } = useMemo(() => {
    const idx = items.findIndex((item) => item.id === dragIdx);
    return { activeItem: items[idx], activeIdx: idx };
  }, [dragIdx, items]);

  return (
    <DndContext
      modifiers={modifiers}
      key={keyPrefix + "dnd-context"}
      onDragCancel={() => setDragIdx(null)}
      onDragStart={(event) => setDragIdx(event.active.id as string)}
      onDragEnd={onDragEnd}
    >
      <div
        key={keyPrefix + "dnd-wrapper"}
        className={`flex flex-col gap-12 ${wrapperClassName}`}
      >
        <SortableContext
          key={keyPrefix + "sortable-context"}
          items={items}
          strategy={strategy}
        >
          {items.map((item, i) => (
            <React.Fragment key={keyPrefix + item.id}>
              {renderItem(item, i)}
            </React.Fragment>
          ))}
        </SortableContext>
        <SortableOverlay modifiers={modifiers}>
          {dragIdx && renderItem(activeItem as T, activeIdx)}
        </SortableOverlay>
      </div>
    </DndContext>
  );
}

const DefaultProps = <T extends BaseItem>(props: Props<T>) => {
  const finalProps: Props<T> = {
    ...props,
    modifiers: props.modifiers ?? [],
    strategy: props.strategy ?? verticalListSortingStrategy,
    wrapperClassName: props.wrapperClassName ?? "",
  };

  return finalProps;
};
