import React, { useCallback, useState } from "react";
import {
  BoldIcon,
  CodeIcon,
  ItalicIcon,
  LinkIcon,
  ListChecksIcon,
  ListOrderedIcon,
  StrikethroughIcon,
  SubscriptIcon,
  SuperscriptIcon,
  UnderlineIcon,
} from "lucide-react";

/* Components */
import { RawInput } from "@components/Input";
import { Button, Dropdown } from "@components";
import { withToolbarMarkDefaults } from "./hoc";

/* Types */
import type { MenuType } from "@components/Dropdown";
import type { ToolbarMarkItem, WithToolbarMarkItemProps } from "../types";

type LinkDropdownProps = {
  Dropdown: MenuType;
  setLink: Function;
  link: string;
};

type MarkType = {
  Bold: React.FC<ToolbarMarkItem>;
  Italic: React.FC<ToolbarMarkItem>;
  StrikeThrough: React.FC<ToolbarMarkItem>;
  Underline: React.FC<ToolbarMarkItem>;
  Superscript: React.FC<ToolbarMarkItem>;
  Subscript: React.FC<ToolbarMarkItem>;
  Link: React.FC<ToolbarMarkItem>;
  BulletList: React.FC<ToolbarMarkItem>;
  OrderedList: React.FC<ToolbarMarkItem>;
  Code: React.FC<ToolbarMarkItem>;
};

const Bold: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleBold().run()}
      {...buttonProps}
      className={editor?.isActive("bold") ? "!border-gradient" : ""}
      leftIcon={() => (
        <BoldIcon
          {...iconProps}
          strokeWidth={editor?.isActive("bold") ? 4 : 1}
        />
      )}
    />
  );
};

const Italic: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleItalic().run()}
      {...buttonProps}
      className={editor?.isActive("italic") ? "!border-gradient" : ""}
      leftIcon={() => (
        <ItalicIcon
          {...iconProps}
          strokeWidth={editor?.isActive("italic") ? 4 : 1}
        />
      )}
    />
  );
};

const StrikeThrough: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleStrike().run()}
      {...buttonProps}
      className={editor?.isActive("strike") ? "!border-gradient" : ""}
      leftIcon={() => (
        <StrikethroughIcon
          {...iconProps}
          strokeWidth={editor?.isActive("strike") ? 4 : 1}
        />
      )}
    />
  );
};

const Underline: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleUnderline().run()}
      {...buttonProps}
      className={editor?.isActive("underline") ? "!border-gradient" : ""}
      leftIcon={() => (
        <UnderlineIcon
          {...iconProps}
          strokeWidth={editor?.isActive("underline") ? 4 : 1}
        />
      )}
    />
  );
};

const Superscript: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleSuperscript().run()}
      {...buttonProps}
      className={editor?.isActive("superscript") ? "!border-gradient" : ""}
      leftIcon={() => (
        <SuperscriptIcon
          {...iconProps}
          strokeWidth={editor?.isActive("superscript") ? 4 : 1}
        />
      )}
    />
  );
};

const Subscript: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleSubscript().run()}
      {...buttonProps}
      className={editor?.isActive("subscript") ? "!border-gradient" : ""}
      leftIcon={() => (
        <SubscriptIcon
          {...iconProps}
          strokeWidth={editor?.isActive("subscript") ? 4 : 1}
        />
      )}
    />
  );
};

const Link: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  const [link, setLink] = useState("");

  return (
    <Dropdown
      portal
      TriggerComponent={() => (
        <Button
          as="a"
          onClick={() =>
            editor?.chain().focus().toggleLink({ href: link }).run()
          }
          {...buttonProps}
          className={editor?.isActive("link") ? "!border-gradient" : ""}
          leftIcon={() => (
            <LinkIcon
              {...iconProps}
              strokeWidth={editor?.isActive("link") ? 4 : 1}
            />
          )}
        />
      )}
    >
      {(Dropdown) => (
        <LinkDropdown link={link} Dropdown={Dropdown} setLink={setLink} />
      )}
    </Dropdown>
  );
};

const LinkDropdown: React.FC<LinkDropdownProps> = (props) => {
  const { Dropdown, setLink, link } = props;

  const { setDropdownOpen } = Dropdown.Context();

  const setEditorLink = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && e.currentTarget.value) {
        setLink(e.currentTarget.value);
      }
    },
    [setLink]
  );

  return (
    <RawInput
      variant="underlined"
      name="set-link"
      value={link}
      wrapperClassName="!bg-black rounded-lg !overflow-hidden"
      inputWrapperClassName="!bg-black !overflow-hidden"
      className="!text-primary-text !bg-neutral-purple !border-transparent"
      placeholder="https://example.com"
      onChange={(e) => {
        setLink(e.target.value);
      }}
      onKeyDown={setEditorLink}
      onBlur={() => setDropdownOpen(false)}
    />
  );
};

const BulletList: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleBulletList().run()}
      {...buttonProps}
      className={editor?.isActive("bulletList") ? "!border-gradient" : ""}
      leftIcon={() => (
        <ListChecksIcon
          {...iconProps}
          strokeWidth={editor?.isActive("bulletList") ? 4 : 1}
        />
      )}
    />
  );
};

const OrderedList: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() => editor?.chain().focus().toggleOrderedList().run()}
      {...buttonProps}
      className={editor?.isActive("orderedList") ? "!border-gradient" : ""}
      leftIcon={() => (
        <ListOrderedIcon
          {...iconProps}
          strokeWidth={editor?.isActive("orderedList") ? 4 : 1}
        />
      )}
    />
  );
};

const Code: React.FC<WithToolbarMarkItemProps> = (props) => {
  const { editor, buttonProps, iconProps } = props;

  return (
    <Button
      onClick={() =>
        editor?.chain().focus().toggleCodeBlock({ language: "python" }).run()
      }
      {...buttonProps}
      className={editor?.isActive("codeBlock") ? "!border-gradient" : ""}
      leftIcon={() => (
        <CodeIcon
          {...iconProps}
          strokeWidth={editor?.isActive("codeBlock") ? 4 : 1}
        />
      )}
    />
  );
};

export const Mark: MarkType = {
  Bold: withToolbarMarkDefaults(Bold),
  Italic: withToolbarMarkDefaults(Italic),
  StrikeThrough: withToolbarMarkDefaults(StrikeThrough),
  Underline: withToolbarMarkDefaults(Underline),
  Superscript: withToolbarMarkDefaults(Superscript),
  Subscript: withToolbarMarkDefaults(Subscript),
  Link: withToolbarMarkDefaults(Link),
  BulletList: withToolbarMarkDefaults(BulletList),
  OrderedList: withToolbarMarkDefaults(OrderedList),
  Code: withToolbarMarkDefaults(Code),
};
