import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
/* Components */
import { Heading } from "@components";
import { StyledTableHead, StyledTableHeading, StyledTableRow } from "../styled";

/* Types */
import type { TableComponent } from "../types";
import { flexRender } from "@tanstack/react-table";

type Props = {
  hasStickyHeader: boolean;
} & TableComponent;

export const TableHead: React.FC<Props> = (props) => {
  const { table, hasStickyHeader } = props;
  const { getHeaderGroups } = table;

  return (
    <StyledTableHead sticky={hasStickyHeader}>
      {getHeaderGroups().map((headerGroup) => (
        // eslint-disable-next-line react/jsx-key
        <StyledTableRow noHover key={headerGroup.id} className="border-b">
          {headerGroup.headers.map((header) => (
            // eslint-disable-next-line react/jsx-key
            <StyledTableHeading colSpan={header.colSpan} key={header.id}>
              <Heading size="base" className="flex gap-2 items-center">
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {header.column.getIsSorted() !== false ? (
                  header.column.getIsSorted() === "desc" ? (
                    <ChevronDownIcon width={24} height={24} />
                  ) : (
                    <ChevronUpIcon width={24} height={24} />
                  )
                ) : null}
              </Heading>
            </StyledTableHeading>
          ))}
        </StyledTableRow>
      ))}
    </StyledTableHead>
  );
};
