export const LOCAL_STORAGE_KEYS = {
  THEME: "ui-theme",
  courseCreation: {
    COURSE_ID: "course-creation-course-id",
  },
  USER_DETAILS: "user-details",
  REDIRECT_URI: "redirect-uri",
};

export const COOKIE_KEYS = {
  AUTH_TOKEN: "token",
  REFRESH_TOKEN: "refresh_token",
};

export const HOME_NAV_LINKS = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Learnings",
    href: "/learnings",
  },
  {
    label: "Projects",
    href: "/projects",
  },
  {
    label: "Services",
    href: "/services",
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
];

export const ParticleOpts = {
  background: {},
  particles: {
    number: {
      value: 300,
    },
    move: {
      direction: "none" as const,
      enable: true,
      outModes: {
        default: "out" as const,
      },
      random: true,
      speed: 0.5,
      straight: false,
    },
    opacity: {
      animation: {
        enable: true,
        speed: 1.25,
        sync: false,
      },
      value: { min: 0.1, max: 1 },
    },
    size: {
      value: { min: 0.5, max: 2.5 },
    },
  },
};

export enum COURSE_COTNENT_TYPE {
  WRITEUP = "WRITEUP",
}

export const JWT_ACCESS_EXPIRY = 0.15; // 15 minutes
export const JWT_REFRESH_EXPIRY = 7 * 24; // 7 days
