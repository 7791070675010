/* Types */
export type ButtonVariants = keyof typeof BUTTON_VARIANTS;
export type ButtonColors = keyof typeof BUTTON_COLORS;
export type ButtonSizes = keyof typeof BUTTON_SIZES;

type CompoundVariantItem = {
  color?: ButtonColors;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  css: any;
};

export type CompoundVariant = CompoundVariantItem[];

/* Most Likely will never require changes */
export const BUTTON_SIZES = {
  xs: {
    padding: "0.125rem 0.25rem",
  },
  sm: {
    padding: "0.25rem 0.375rem",
  },
  base: {
    padding: "0.5rem 0.75rem",
  },
  lg: {
    padding: "0.625rem 1rem",
  },
  xl: {
    padding: "0.75rem 1.25rem",
  },
};

/* May add new variants */
export const BUTTON_VARIANTS = {
  solid: {
    background: "var(--main)",
    color: "var(--support)",
    border: "1px solid transparent",

    '&[data-state="disabled"]': {
      background: "$disabled",
      "&:is(p, span)": {
        color: "#000000 !important",
      },

      "&:hover": {
        background: "$disabled",
      },
    },

    "&:hover, &:focus": {
      background: "rgba(var(--hover), 80%)",
    },
  },
  outline: {
    background: "transparent",
    color: "var(--main)",
    border: "1px solid var(--main)",

    '&[data-state="disabled"]': {
      borderColor: "$disabled",
      color: "$disabled",
      "&:hover": {
        background: "transparent",
        borderColor: "$disabled",
      },
    },

    "&:hover, &:focus": {
      background: "rgba(var(--hover), 30%)",
      borderColor: "rgba(var(--hover), 30%)",
    },
  },
  ghost: {
    background: "$neutral-gray",
    color: "var(--support)",
    border: "1px solid transparent",

    '&[data-state="disabled"]': {
      borderColor: "$disabled",
      color: "$disabled",
      "&:hover": {
        background: "transparent",
        borderColor: "$disabled",
      },
    },
  },
  text: {
    background: "transparent",
    color: "var(--main)",

    '&[data-state="disabled"]': {
      color: "$disabled",
    },
  },
  icon: {
    background: "transparent",
    color: "$primary-text",

    '&[data-state="disabled"]': {
      color: "$disabled",
      "&:hover": {
        color: "$disabled",
      },
    },

    "&:hover, &:focus": {
      color: "var(--main)",
    },
  },
  link: {
    background: "transparent",
    color: "var(--main)",
    textDecoration: "underline",
    '&[data-state="disabled"]': {
      color: "$disabled",
    },
  },
};

/* Definitely requires changes */
export const BUTTON_COLORS = {
  primary: {
    "--main": "rgb(var(--primary))",
    "--support": "rgb(var(--neutral-white))",
    "--hover": `var(--primary)`,
  },
  gradient: {
    "--main": "var(--neutral-gradient)",
    "--support": "rgb(var(--primary-text))",
    "--hover": "var(--neutral-gradient)",
  },
  // --colors-{color} are defined in stitches.config.ts
  red: {
    "--main": `var(--colors-red)`,
    "--support": "var(--colors-white)",
    "--hover": `var(--colors-rgbRed)`,
  },
  green: {
    "--main": `var(--colors-green)`,
    "--support": "var(--colors-white)",
    "--hover": `var(--colors-green)`,
  },
};

/* Additional styles added as per design requirements */
export const BUTTON_COMPOUND_VARIANTS: CompoundVariant = [
  {
    variant: "solid",
    color: "gradient",
    css: {
      "&:hover, &:focus": {
        background: "var(--hover)",
      },
    },
  },
  {
    variant: "outline",
    color: "gradient",
    css: {
      position: "relative",
      "&::before": {
        content: "",
        position: "absolute",
        zIndex: -1,
        inset: 0,
        padding: "2px",
        borderRadius: "8px",
        background: "var(--main)",
        WebkitMask:
          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
      },
      color: "var(--support)",
    },
  },
  {
    variant: "text",
    color: "gradient",
    css: {
      color: "var(--support)",
    },
  },
];
