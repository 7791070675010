/* Types */
export type BreakpointKeys = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
export type BreakpointType = Record<BreakpointKeys, string>;

export const Breakpoints = {
  xs: 420,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as Record<BreakpointKeys, number>;

export const Colors = {
  primary: "rgb(var(--primary))",
  secondary: "rgb(var(--secondary))",
  "primary-text": "rgb(var(--primary-text))",
  "neutral-white": "rgba(var(--neutral-white))",
  "neutral-gray": "rgba(var(--neutral-gray), 0.1)",
  "neutral-black": "rgb(var(--neutral-black))",
  "neutral-purple": "rgb(var(--neutral-purple))",
  montonic: "rgb(var(--montonic))",
  grey: "rgb(var(--neutral-gray))",
  disabled: "rgb(var(--disabled))",

  // Gradients don't need `rgb()`.
  "neutral-black-gradient": "var(--neutral-black-gradient)",
  "neutral-gradient": "var(--neutral-gradient)",
  "gradient-bg": "var(--gradient-bg)",
};

export const borderRadius = "var(--border-radius)";
