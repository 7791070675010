/* Extensions */

import { createLowlight } from "lowlight";

import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Strike from "@tiptap/extension-strike";
import Youtube from "@tiptap/extension-youtube";
import { Color } from "@tiptap/extension-color";
import Image from "./components/ResizeableImage";
import Underline from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import TextStyle from "@tiptap/extension-text-style";
import TextAlign from "@tiptap/extension-text-align";
import FontFamily from "@tiptap/extension-font-family";
import Superscript from "@tiptap/extension-superscript";
import Placeholder from "@tiptap/extension-placeholder";
import FileHandler from "@tiptap-pro/extension-file-handler";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";

/* Syntax Highlighting */
import python from "highlight.js/lib/languages/python";
import js from "highlight.js/lib/languages/javascript";

/* Type */
import { ExtensionType } from "./types";

type ExtensionKeys = keyof typeof DEFAULT_EXTENSIONS;
type ExtensionTypes = (typeof DEFAULT_EXTENSIONS)[ExtensionKeys];

const lowlight = createLowlight();

// Register languages
lowlight.register({
  python,
  js,
});

const BASE_EXTENSIONS = {
  starterKit: StarterKit.configure({
    history: false,
    codeBlock: false,
    strike: false,
  }),
  textStyle: TextStyle,
  fontFamily: FontFamily,
  image: Image.configure({
    inline: true,
  }),
  placeholder: Placeholder,
  fileHandler: FileHandler,
};

const DEFAULT_EXTENSIONS = {
  ...BASE_EXTENSIONS,
  textAlign: TextAlign,
  link: Link,
  strike: Strike,
  underline: Underline,
  subscript: Subscript,
  superscript: Superscript,
  codeBlock: CodeBlockLowlight.configure({
    lowlight,
    exitOnArrowDown: false,
  }),
  color: Color.configure({ types: ["textStyle"] }),
  youtube: Youtube.configure({
    modestBranding: true,
  }),
};

export const generateExtensions = (includes: ExtensionType[]) => {
  let extensionsMap = new Set(includes);

  if (includes?.length === 0) return DEFAULT_EXTENSIONS;

  const extensions: Record<string, ExtensionTypes> = BASE_EXTENSIONS;

  extensionsMap?.forEach((extension) => {
    const ext = ExtensionAttributes(extension);
    extensions[ext.name] = ext.extension;
  });

  return extensions;
};

const ExtensionAttributes = (ext: ExtensionType) => {
  switch (ext) {
    case "TextAlign":
      return { name: "textAlign", extension: DEFAULT_EXTENSIONS.textAlign };
    case "Link":
      return { name: "link", extension: DEFAULT_EXTENSIONS.link };
    case "Strike":
      return { name: "strike", extension: DEFAULT_EXTENSIONS.strike };
    case "Underline":
      return { name: "underline", extension: DEFAULT_EXTENSIONS.underline };
    case "Subscript":
      return { name: "subscript", extension: DEFAULT_EXTENSIONS.subscript };
    case "Superscript":
      return { name: "superscript", extension: DEFAULT_EXTENSIONS.superscript };
    default:
      throw new Error("Invalid Extension");
  }
};
