import { styled } from "@styles/stitches";

export const StyledContainer = styled("div", {
  position: "relative",
  borderRadius: "8px",
  overflow: "hidden",
  color: "$grey",
  transition: "all 0.2s ease-in-out",
  "&:focus-within": {
    outline: "$neutral-purple",
    color: "$neutral-purple",
  },
});

export const StyledSearchBar = styled("input", {
  padding: "0.5rem 1rem",
  background: "$gradient-bg",
  color: "$primary-text",
  width: "100%",
  border: "1px solid $grey",
  borderRadius: "8px",
  transition: "border 250ms ease-in-out",

  "&:placeholder": {
    color: "$grey",
  },

  "&:focus": {
    border: "1px solid $neutral-purple",
    outline: "none",
  },
});
