import { styled } from "@styles/stitches";

export const EffectCircle = styled("div", {
  position: "absolute",
  background: "rgba(114,62,199,0.2)",
  padding: "var(--size)",
  zIndex: 10,
  transform: "translate(-50%, -50%)",
  borderRadius: "100%",
  variants: {
    color: {
      purple: {
        background: "rgba(114,62,199,var(--bg-intensity))",
        boxShadow:
          "0px 0px 130px 61px rgba(114,62,199, var(--shadow-intensity))",
      },
      pink: {
        background: "rgba(202, 98, 219, var(--bg-intensity))",
        boxShadow:
          "0px 0px 130px 61px rgba(202, 98, 219, var(--shadow-intensity))",
      },
    },
  },
});
