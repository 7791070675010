import React from "react";

/* Styled */
import { StyledSwitch, StyledThumb } from "./styled";

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  name: string;
  defaultChecked?: boolean;
  onChange: (checked: boolean) => void;
  wrapperClassName?: string;
};

export const Switch: React.FC<Props> = (props) => {
  const { name, disabled, onChange, wrapperClassName, defaultChecked } =
    DefaultProps(props);

  return (
    <fieldset className={wrapperClassName}>
      <StyledSwitch
        className="bg-gray-200"
        name={name}
        defaultChecked={defaultChecked}
        onCheckedChange={onChange}
        disabled={disabled}
      >
        <StyledThumb />
      </StyledSwitch>
    </fieldset>
  );
};

const DefaultProps = (props: Props): Props => {
  return {
    ...props,
    defaultChecked: props.defaultChecked || false,
    disabled: props.disabled || false,
    wrapperClassName: props.wrapperClassName || "",
  };
};
