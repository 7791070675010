import { useCallback, useMemo } from "react";
import { getLocalStoragevalue, setLocalStoragevalue } from "@src/utils";

/* Consts */
import { LOCAL_STORAGE_KEYS } from "../consts";

type SetValueInterface<T> = (key: string, value: T) => boolean;

type Values<T = unknown> = {
  key: string;
  value: T;
};
type setMultipleInterface<T> = (values: Values<T>[]) => void;

type removeValueInterface = (key: string) => void;

export const useLocalStorage = <T = string>() => {
  const KEYS = useMemo(() => LOCAL_STORAGE_KEYS, []);

  const get = useCallback(getLocalStoragevalue, []);
  const set = useCallback<SetValueInterface<T>>(setLocalStoragevalue, []);

  const setMultiple = useCallback<setMultipleInterface<T>>(
    (values) => {
      values.forEach((value) => {
        set(value.key, value.value);
      });
    },
    [set]
  );

  const remove = useCallback<removeValueInterface>((key) => {
    localStorage.removeItem(key);
  }, []);

  return {
    get,
    set,
    setMultiple,
    remove,
    KEYS,
  };
};
