export const ROW_OPTIONS = [
  {
    label: "10 Rows",
    value: 10,
  },
  {
    label: "25 Rows",
    value: 25,
  },
  {
    label: "50 Rows",
    value: 50,
  },
  {
    label: "75 Rows",
    value: 75,
  },
  {
    label: "100 Rows",
    value: 100,
  },
];
