import React from "react";

/* Components */
import { StyledContainer } from "./styled";

type Props = {
  children?: React.ReactNode;
} & typeof StyledContainer.defaultProps;

export const Container: React.FC<Props> = (props) => {
  const { ...rest } = props;

  return <StyledContainer {...rest}>{props.children}</StyledContainer>;
};
