import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";

/* Types */
import type { DragOverlayProps, DropAnimation } from "@dnd-kit/core";

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.4",
      },
    },
  }),
};

type Props = {
  children?: React.ReactNode;
} & DragOverlayProps;

export const SortableOverlay = (props: Props) => {
  return (
    <DragOverlay {...props} dropAnimation={dropAnimationConfig}>
      {props.children}
    </DragOverlay>
  );
};
